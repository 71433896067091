import { useCallback, useEffect, useRef, useState } from "react";
import '../css/LDProjectCards.scss';

function LDProjectCards(props) {
    const [data, setData] = useState([]);

    const fetchData = useCallback(async () => {
        // Get timestamp
        const timestamp = Date.now();
        const response = await fetch('/data/other_projects.json?ver=' + timestamp);
        if (response.ok) {
            const json = await response.json();
            setData(json);
        }
        else {
            alert('Failed to load other projects data.');
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (data.length == 0)
        return null;

    return (
        <div className='Flex F-Col Gap-M'>
            <h6 className='Title-3 Green-1'>OTHER PROJECTS</h6>
            <div className='Other-Project-Box'>
                {data && data.map((d, i) => (
                    <ProjectCard key={i} title={d.title} description={d.description} image={d.image} />
                ))}
            </div>
        </div>
    );
}

export default LDProjectCards;

function ProjectCard(props) {
    const { title, description, image } = props;
    const projectCardRef = useRef(null);
    const comingSoonCardRef = useRef(null);

    useEffect(() => {
        projectCardRef.current.style.marginTop = -comingSoonCardRef.current.offsetHeight + 'px';
    }, [])

    return (
        <div>
            <div ref={comingSoonCardRef} className='Coming-Soon-Card'>
                <p className='Title-3 Black-1'>COMING SOON</p>
                <p className='Body-3 Black-3' style={{textAlign: 'center'}}>If you’re curious, just reach me out</p>
            </div>
            <div ref={projectCardRef} className='Project-Card'>
                <img src={image} className='Project-Image' alt={title} />
                <div className='Flex F-Col Gap-S'>
                    <p className='Body-2 Black-1' style={
                        {
                            fontWeight: 600,
                            fontSize: '22px',
                            lineHeight: '35.2px',
                            letterSpacing: '1%'
                        }
                    }>{title}</p>
                    <p className='Body-2 Black-2'>{description}</p>
                </div>
            </div>
        </div>
    );
}