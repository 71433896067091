import { useEffect, useRef } from "react";

function AnimaWhiter(props) {
    // React utils
    const ref = useRef(null);

    // Constants
    const TRANSITION_DELTA = 10;
    const WHITER_OPACITY_TRANSITION_TIME = 1000;

    // Init callback
    useEffect(() => {
        if (!ref) return;
        // Disable body scroll x
        document.body.style.overflowX = 'hidden';

        const windowHeight = window.innerHeight;
        ref.current.style.width = window.innerWidth + "px";
        ref.current.style.height = windowHeight + "px";

        // Flashing animation
        ref.current.style.display = 'absolute';
        ref.current.style.opacity = 1;
        setTimeout(() => {
            ref.current.style.opacity = 0;
            setTimeout(() => {
                // Hide whiter
                ref.current.style.display = 'none';
    
                // Enable body scroll x
                document.body.style.overflowX = 'auto';
            }, WHITER_OPACITY_TRANSITION_TIME);
        }, TRANSITION_DELTA);

    }, [ref]);

    return (
        <div ref={ref} className='DDWhiter'></div>
    );
}

export default AnimaWhiter;