import '../css/LDEssay.scss';
import getParagraphOnlyFromMarkdown from '../utility/getParagraphOnlyFromMarkdown';
import essayArrow from './../images/essay-arrow.svg';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import arrowLeft from './../images/arrow-left.svg';

function EssayMenu(props) {
    const { title, essays, withSeeAllEssay } = props;

    // React utils
    const navigate = useNavigate();
    const blogRef = useRef(null);
    
    const openEssay = (path) => {
        navigate('/essay/' + path);
    }

    // Animation callback
    useEffect(() => {
        // Set opacity to 1
        blogRef.current.style.opacity = 1;
    }, [blogRef])

    return (
        <div className="LD-Essay">
            <p className="Title">{title}</p>
            <div ref={blogRef} className='Blog'>
                {essays?.map((item, i) => (
                    <div 
                        className='Blog-Item' 
                        key={`essay-${i}`} 
                        onClick={() => openEssay(item.path)}
                    >
                        <BlogItemS title={item.title} content={getParagraphOnlyFromMarkdown(item.content)} />
                    </div>
                ))}
            </div>
            {withSeeAllEssay ? (
                <button 
                    className="Flex Gap-S Cursor-Pointer" 
                    onClick={() => navigate('/essay')} 
                    style={{
                        alignItems: 'center', 
                        background: 'none', 
                        border: 'none'
                    }}>
                    <img src={arrowLeft} width='24px' height='24px' alt='Icon'/>
                    <p className='Body-2 FW-600 Black-1'>See all essays</p>
                </button>
            ) : null}
        </div>
    );
}

export default EssayMenu;

function BlogItemS(props) {
    const { title, content } = props;

    const [isRightLoaded, setIsRightLoaded] = useState(false);

    const handleRightLoad = () => {
        console.log('onload');
        setIsRightLoaded(true);
    };

    return (
        <>
            <div className='Left'>
                <p className='Blog-Title'>{title}</p>
                {isRightLoaded ? <BlogTruncatedText text={content} /> : null }
            </div>
            <div className='Right'>
                <img src={essayArrow} alt='Icon' onLoad={handleRightLoad}/>
            </div>
        </>
    );
}

function BlogTruncatedText(props) {
    const { text } = props;
    
    const [textContent, setTextContent] = useState('');
    const textRef = useRef(null);

    useEffect(() => {
        const currentWidth = textRef.current.offsetWidth;
        textRef.current.style.whiteSpace = 'nowrap';
        textRef.current.style.width = currentWidth + 'px';
        console.log(currentWidth);
        setTextContent(text);
    }, []);

    return (
        <div className="Blog-Content-Truncated" ref={textRef}>
            <p>{textContent}</p>
        </div>
    );
}