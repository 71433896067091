import { useEffect, useRef } from "react";
import LDAbout from "./LDAbout";
import LDEssay from "./LDEssay";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import AnimaWhiter from "./AnimaWhiter";

function LDPageForEssay(props) {
    const { aboutData } = props;

    // React utils
    const contentRef = useRef(null);
    const navigate = useNavigate();

    // Init callback
    useEffect(() => {
        document.title = 'Faw, Designer';
    }, []);

    // Animation callback
    useEffect(() => {
        // Set opacity of content
        contentRef.current.style.opacity = 1;
    }, [contentRef])

    return (
        <div>
            <AnimaWhiter />
            <NavBar customClass='Nv-Bg-White-2 Nv-Sticky' active='essay' isSticky={true} designAction={() => navigate('/')} />
            <div ref={contentRef} className="LD-App">
                <LDEssay />
                <LDAbout data={aboutData} />
            </div>
        </div>
    );
}

export default LDPageForEssay;