import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import '../css/AdmPage.scss';
import config from "../config.json";
import getToken from "../utility/getToken";
import AdmEssayForm from "./AdmEssayForm";

function AdmPageEditEssay(props) {
    const { essayId } = useParams();

    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [label, setLabel] = useState('');
    const [content, setContent] = useState('');
    const [footer, setFooter] = useState('');

    const fetchData = useCallback(async () => {
        const response = await fetch(`${config.API_URL}/get_post_by_path.php?p=${essayId}`);
        if (response.ok) {
            const json = await response.json();
            setTitle(json.title);
            setContent(json.content);
            setLabel(json.label);
            setFooter(json.footer);
        } else {
            alert('Failed to fetch essay data');
        }
    }, [essayId]);

    useEffect(() => {
        console.log('Fetching data...');
        fetchData();
    }, [fetchData]);

    const doEdit = async () => {
        const response = await fetch(`${config.API_URL}/update_post.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                path: essayId,
                title: title,
                content: content,
                footer: footer,
                label: label
            })
        });

        if (response.ok) {
            const json = await response.json();
            alert('Essay has been saved successfully');
            // Change path, in case the title is changed
            navigate(`/admin/essay/edit/${json.new_path}`);
        }
        else {
            alert('Failed to save essay');
        }
    };

    useEffect(() => {
        // Set title
        document.title = 'Admin Panel - Edit Essay';
    }, []);

    return (
        <div className='Flex F-Col Gap-M Padding-XL'>
            <button style={{width: '100px'}} className='Button Padding-S Bg-Black-2 Bg-Black-1-Hover Body-3 White-1' onClick={() => navigate('/admin/essays')}>Back</button>
            <p className='Title-3'>Edit Essay</p>
            <AdmEssayForm title={title} label={label} content={content} footer={footer} setTitle={setTitle} setLabel={setLabel} setContent={setContent} setFooter={setFooter} onSave={doEdit} saveText='Save' />
        </div>
    );
}

export default AdmPageEditEssay;