import { useCallback, useEffect, useState } from "react";
import config from "../config.json";
import EssayMenu from "./EssayMenu";

function PrevAndNextEssay(props) {
    const { essayId } = props;
    const [essays, setEssays] = useState(null);

    const fetchData = useCallback(async () => {
        const response = await fetch(`${config.API_URL}/get_prev_and_next_post.php?p=${essayId}`);
        if (response.ok) {
            const json = await response.json();
            setEssays(json);
            console.log(json);
        } else {
            alert('Failed to fetch previous and next essay data');
        }
    }, [essayId]);

    useEffect(() => {
        fetchData();
        console.log('Fetching next and prev')
    }, [fetchData]);

    return (
        <EssayMenu title='More' essays={essays} withSeeAllEssay />
    );
}

export default PrevAndNextEssay;