import '../css/EssayQuote.scss';

function EssayQuote(props) {
    const { text } = props;
    return (
        <div className='Essay-Quote'>
            <p className='Body-2 Black-1' dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
    );
}

export default EssayQuote;