import '../css/NavBar.scss';
import logo from '../images/logo-v2.png';
import eclipse from '../images/eclipse.svg';
function NavBar(props) {
    const { customClass, logoAction, designAction, essayAction, isSticky, active } = props;

    return (
        <div className={`Nav-Bar-Container ${isSticky ? 'Nv-Sticky' : ''} ${customClass ?? ''}`}>
            <div className={`Nav-Bar`}>
                <img src={logo} alt='Logo' className='Nav-Logo' onClick={logoAction} draggable={false} width={84} height={28} />
                <div className={`Nav-Logo-Popover`}>
                    <p>fawwazy.com</p>
                </div>
                <div className='Links'>
                    <HeaderLink text='DESIGN' action={designAction} isActive={active === 'design'} />
                    <HeaderLink text='ESSAYS' action={essayAction} isActive={active === 'essay'} />
                </div>
            </div>
        </div>
    )
}

export default NavBar;

function HeaderLink(props) {
    const { text, action, isActive } = props;

    const onPress = () => {
        if (action)
            action();
    };

    return (
        <div className={`Link-Item ${isActive ? 'Active' : ''}`} onClick={onPress}>
            <p className='Link-Text'>{text}</p>
            <img src={eclipse} alt='Eclipse' className='Eclipse' />
        </div>
    );
}