import star from './../images/star.svg';
import './../App.scss';
import './../css/LDWork.scss';
import { useNavigate } from 'react-router-dom';
import isVideo from '../utility/isVideo';
import { forwardRef } from 'react';

const LDWork = forwardRef((props, ref) => {
  const navigate = useNavigate();

  const TRANSITION_DELTA = 10;
  const ANIMA_SCALE_TRANSITION = 500;
  const ANIMA_BG_COL_TRANSITION = 350;

  const openWork = async (element, i, page) => {

    // Get position of element
    const scrollY = window.scrollY;
    const elTop = element.getBoundingClientRect().top;
    const elLeft = element.getBoundingClientRect().left;
    const elWidth = element.offsetWidth;
    const elHeight = element.offsetHeight;

    // Create a div on position
    const div = document.createElement("div");
    div.classList.add("Anima");
    div.style.position = 'absolute';
    div.style.backgroundColor = '#FFF';
    div.style.top = `${scrollY +elTop}px`;
    div.style.left = `${elLeft}px`;
    div.style.zIndex = 99;
    div.style.width = `${elWidth}px`;
    div.style.height = `${elHeight}px`;
    document.querySelector('.LD-App').appendChild(div);

    setTimeout(() => {
      // Disable scroll in body temporarily
      document.body.style.overflow = 'hidden';
      const scaler = Math.max(
        1 + (window.innerWidth) / elWidth,
        1 + (window.innerHeight) / elHeight
      );
      div.style.transform = `scale(${scaler})`;
      element.classList.add('Item-Transform');
      setTimeout(() => {
        div.style.backgroundColor = '#000';
        setTimeout(() => {
          // Enable scroll in body
          // document.body.style.overflow = 'auto';
          navigate(page);
        }, ANIMA_BG_COL_TRANSITION);
      }, ANIMA_SCALE_TRANSITION);
    }, TRANSITION_DELTA);
  };


    return (
      <div ref={ref} className="LD-Work">
        <p className="Title">Work</p>
        {props.data.map((item, i, {length}) => ([
          <div key={`work-item-${i}`} className="Item" onClick={(e) => openWork(e.currentTarget, i, item.page)}>
            {!isVideo(item.image_path) ? (
              <div className="Image">
                <img src={item.image_path} alt={item.title} />
              </div>
            ) : (
              <div className="Video">
                <video
                  src={item.image_path}
                  autoPlay
                  muted
                  playsInline
                  loop
                />
              </div>
            )}
            <Heading text={item.title} />
            <BodyElement text={item.text} list={item.list} i={i} role={item.role} skill={item.skill} page={item.page} setPage={props.setPage}/>
          </div>,
          (i + 1 === length ? null : (<div key={`work-divider-${i}`} className="Divider" />))
        ]))}
      </div>
  );
});

function Heading(props) {
  let text = props.text;

  return (
    <div className="Heading">
      <p>{text}</p>
    </div>
  )
}

function BodyElement(props) {
  return (
    <div className="Body">
      <BodyLeft i={props.i} text={props.text} list={props.list} />
      <div className="Right">
        <BodyRightRole i={props.i} sep=", " title="Role" list={props.role} />
        <BodyRightSkill i={props.i} sep="<br />" title="Skills" list={props.skill} />
        {/* <ReadFullStory page={props.page} setPage={props.setPage} /> */}
      </div>
    </div>
  );
}

function BodyLeft(props) {
  return (
    <div className="Left">
      <p className="Description">{props.text}</p>
      <BodyLeftList i={props.i} list={props.list} />
    </div>
  );
}

function BodyLeftList(props) {
  return (
    props.list.map((item, j) => (
      <div className="List-Element" key={`work-list-${item.i}-${j}`}>
        <div className="Icon">
          <img src={star} alt="Star Icon" />
        </div>
        <p className="Text" dangerouslySetInnerHTML={{ __html: item }}></p>
      </div>
    ))
  );
}

function BodyRightRole(props) {
  let itemList = props.list;
  // Make a string consists of seperated comma of itemList elements
  let item = '';
  for (let i = 0; i < itemList.length; i++) {
    item += itemList[i];
    if (i < itemList.length - 1) {
      item += props.sep;
    }
  }
  return (
    <div className="Role">
      <p className="Black-2">{props.title}</p>
      <p className="Black-1" dangerouslySetInnerHTML={{ __html: item }}></p>
    </div>
  );
}

function BodyRightSkill(props) {
  let itemList = props.list;
  // Make a string consists of seperated comma of itemList elements
  let item = '';
  for (let i = 0; i < itemList.length; i++) {
    item += itemList[i];
    if (i < itemList.length - 1) {
      item += props.sep;
    }
  }
  return (
    <div className="Skill">
      <p className="Black-2">{props.title}</p>
      <p className="Black-1" dangerouslySetInnerHTML={{ __html: item }}></p>
    </div>
  );
}
/*
function ReadFullStory(props) {
  return (
    <div className="Read-Full-Story">
      <p className="Black-1 Body-3">Read the full story</p>
      <img src={arrow} alt="Link Icon" className='Full-Story-Arrow' />
    </div>
  )
}
*/
export default LDWork;
