import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import '../css/AdmPage.scss';
import config from "../config.json";
import getToken from "../utility/getToken";
import Essay from "./Essay";
import AdmSidebar from "./AdmSidebar";

function AdmPageDeleteEssay(props) {
    const { essayId } = useParams();

    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [label, setLabel] = useState('');
    const [content, setContent] = useState('');
    const [footer, setFooter] = useState('');

    const fetchData = useCallback(async () => {
        const response = await fetch(`${config.API_URL}/get_post_by_path.php?p=${essayId}`);
        if (response.ok) {
            const json = await response.json();
            setTitle(json.title);
            setContent(json.content);
            setLabel(json.label);
            setFooter(json.footer);
        } else {
            alert('Failed to fetch essay data');
        }
    }, [essayId]);

    useEffect(() => {
        console.log('Fetching data...');
        fetchData();
    }, [fetchData]);

    const doDelete = async () => {
        const response = await fetch(`${config.API_URL}/delete_post.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                path: essayId
            })
        });

        if (response.ok) {
            alert('Essay has been deleted successfully');
            // Change path, in case the title is changed
            navigate(`/admin/essays`);
        }
        else {
            alert('Failed to delete the essay');
        }
    };

    useEffect(() => {
        // Set title
        document.title = 'Admin Panel - Delete Essay';
    }, []);

    return (
        <div className='Flex'>
            <AdmSidebar />
            <div className='Flex F-Col Gap-M Padding-XL'>
                <p className='Title-3'>Delete Essay</p>
                <div className='Flex Gap-XL'>
                    <div className='Flex F-Col Gap-S Pos-Sticky F-Align-Self-Start Padding-Top-L' style={{width: '30%', top: '35%'}}>
                        <h2 className='Title-2 Green-1'>Confirm</h2>
                        <div className='Flex F-Col'>
                            <p className='Body-2 Black-1'>Are you sure you will <span className='FW-600'>DELETE</span> this essay?</p>
                            <p className='Body-2 Black-1'>This action can <span className='FW-600'>NOT</span> be undone.</p>
                        </div>
                        <div className='Flex Gap-M Margin-Top-S'>
                            <button className='Button Padding-S Bg-Black-2 Bg-Black-3-Hover White-1' style={{width: '50%'}} onClick={doDelete}>YES</button>
                            <button className='Button Padding-S Bg-Black-1 Bg-Black-3-Hover White-1' style={{width: '50%'}} onClick={() => navigate(`/admin/essays`)}>NO</button>
                        </div>
                    </div>
                    <div style={{width: '70%'}}>
                        <Essay title={title} label={label} content={content} footer={footer} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdmPageDeleteEssay;