import { useCallback, useEffect, useRef, useState } from 'react';
import '../../css/DD/DDCarousel.scss';

function DDCarousel(props) {
    const { data, parentRef } = props;
    const [parentWidth, setParentWidth] = useState(0);
    const ourRef = useRef(null);
    const swapperRef = useRef(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });
    const handleDragStart = useCallback((e) => {
        if (!ourRef.current) return
        const slider = ourRef.current.children[0];
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const scrollLeft = slider.scrollLeft;
        const scrollTop = slider.scrollTop;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
        setIsMouseDown(true);
    }, [ourRef]);

    const handleDragEnd = () => {
        if (!ourRef.current) return;
        setIsMouseDown(false);
    }

    const handleDrag = (e) => {
        if (!isMouseDown || !ourRef.current) return;
        e.preventDefault();
        const slider = ourRef.current.children[0];
        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    }

    useEffect(() => {
        const t = parentRef.current.getBoundingClientRect();
        // Get width of the container
        // 24px is Space M (defined) of parent padding
        const width = t.width;
        setParentWidth(width);
    }, [parentRef]);

    useEffect(() => {
        // If desktop size < 740px
        if (window.innerWidth < 740) {
            // Get ourRef rect
            const t = ourRef.current.getBoundingClientRect();
            // Set swapperRef margin right to - parentRect left
            swapperRef.current.style.marginRight = -t.left + 'px';
        }
        console.log('Window width', window.innerWidth);
    }, [swapperRef]);

    return (
        <div ref={ourRef} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag} className='Carousel' style={{ width: parentWidth + 'px'}}>
            <div ref={swapperRef} className='Carousel-Swapper'>
                {data.image.map((d, k) => (
                    <div key={`carousel-${k}`} className='Carousel-Image'>
                        <img className='Carousel-Image' 
                            src={d.url} 
                            alt={d.alt ?? 'Carousel ' + k}
                            width={data.width} 
                            height={data.height} 
                            draggable={false} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DDCarousel;