import '../../css/DD/DDEmbed.scss';

function DDEmbed(props) {
    const { data } = props;
    return (
        <video 
            src={data.src}
            autoPlay
            muted
            loop
            playsInline
            name={data.title}
            style={{
                width: '100%',
                height: data.height ?? 'auto',
            }}
        />
    );
}

export default DDEmbed;