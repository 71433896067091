import "./../../css/DD/Timeline.scss";
import arrow5 from './../../images/arrow5.svg';

function DDTimelineLeft(props) {
    const { data } = props;
    return (
        <div className="Left">
            <div className="Text23">
                <p className="Body-3 Black-1">making sense the</p>
                <p className="Body-2 Bold Black-1">Status Quo</p>
            </div>
            <img src={arrow5} alt="Timeline Arrow" className="Timeline-Arrow" width={data.arrowWidth} />
            <div className="Text23">
                <p className="Body-3 Black-1">building desired</p>
                <p className="Body-2 Bold Black-1">Future</p>
            </div>
        </div>
    );
}

export default DDTimelineLeft;