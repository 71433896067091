import { useEffect } from 'react';
import '../css/AdmPage.scss';
import AdmSidebar from "./AdmSidebar";

function AdmPage(props) {
    // Init callback
    useEffect(() => {
        // Set title
        document.title = 'Admin Panel';
    }, []);

    return (
        <div className="Flex">
            <AdmSidebar />
            <div className='Padding-XL'>
                <p className='Body-2 Black-1'>Welcome, Mr. Fawwazy!</p>
            </div>
        </div>
    );
}

export default AdmPage;