import arrow from './../images/arrow.svg';
import './../App.scss';
import './../css/LDAbout.scss';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

function LDAbout(props) {
  return (
      <div className="LD-About">
        <p className="Title">About Me</p>
        <p className="Text1" dangerouslySetInnerHTML={{ __html: props.data.text1 }}></p>
        <p className="Text2" dangerouslySetInnerHTML={{ __html: props.data.text2 }}></p>
        <div className="Footer">
          <LinkSocialSection data={props.data.social} />
          {/* <LinkOtherSection data={props.data.other} /> */}
        </div>
      </div>
  );
}

export default LDAbout;

function LinkSocialSection(props) {
  return (
    <div className="Social-Section">
      {/* <p className="Title">Find me on socials</p> */}
      <div className="Link">
        {props.data.map((item, i) => (
          // If item name is Email
          item.name === 'Email' ? (
            <LinkSocialItem key={`social-${i}`} href={item.value} text={item.name} type={0}/>
          ) : (
            <LinkSocialItem key={`social-${i}`} href={item.value} text={item.name} type={1}/>
          )
        ))}
      </div>
    </div>
  );
}

function LinkSocialItem(props) {
  const copyFunc = (e) => {
    e.preventDefault();
    alert('Email ' + props.href + ' has been copied.');
    // Copy props href to clipboard
    navigator.clipboard.writeText(props.href);
  };
  const linkText = useRef(null);
  useEffect(() => {
    // Add 1px to the width
    linkText.current.style.width = linkText.current.offsetWidth + 1 + 'px';
  }, []);
  if (props.type == 0) {
    return (
      <a href='#' onClick={copyFunc} ref={linkText}>
        <p>{props.text}</p>
        <img src={arrow} alt="Link Icon" className='FW-600' />
      </a>
    )
  }
  else {
    return (
      <a href={props.href} ref={linkText}>
        <p>{props.text}</p>
        <img src={arrow} alt="Link Icon" className='FW-600' />
      </a>
    )
  }
}

// Property validator
LinkSocialItem.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.number,
}


function LinkOtherSection(props) {
  return (
    <div className="Other-Section">
      <p className="Title">Other resource</p>
      <div className="Link">
        {props.data.map((item, i) => (
          <LinkOtherItem key={`other-${i}`} href={item.value} text={item.name} />
        ))}
      </div>
    </div>
  );
}

function LinkOtherItem(props) {
  return (
    <a href={props.href}>
      <p className="Black-1 Body-3">{props.text}</p>
      <img src={arrow} alt="Link Icon" />
    </a>
  );
}
