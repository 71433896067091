import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./../images/logo.svg";
import removeToken from "../utility/removeToken";

function AdmSidebar(props) {
    const navigate = useNavigate();

    const [menu, setMenu] = useState('essays');

    /**
     * Logout
     */
    const doLogOut = () => {
        removeToken();
        navigate('/waking-up');
    };

    useEffect(() => {
        // Get latest path from URL
        const path = window.location.pathname;
        setMenu(path.split('/').pop());
    }, []);

    return (
        <div className="Adm-Sidebar">
                <img src={logo} alt="Logo" />
                <div className='Flex F-Col Gap-S'>
                    <button className={`Body-3 Button Padding-S ${menu === 'essays' ? 'Bg-Green-1' : 'Bg-White-1 Bg-White-3-Hover'}`} onClick={() => navigate('/admin/essays')}>Manage Essays</button>
                    <button className={`Body-3 Button Padding-S ${menu === 'file_manager' ? 'Bg-Green-1' : 'Bg-White-1 Bg-White-3-Hover'}`} onClick={() => navigate('/admin/file_manager')}>File Manager</button>
                    <button className={`Body-3 Button Padding-S ${menu === 'change_password' ? 'Bg-Green-1' : 'Bg-White-1 Bg-White-3-Hover'}`} onClick={() => navigate('/admin/change_password')}>Change Password</button>
                    <button className={`Body-3 Button Padding-S Bg-White-1 Bg-White-3-Hover`} onClick={doLogOut}>Logout</button>
                </div>
        </div>
    );
}

export default AdmSidebar;