import './../../css/DD/DDResultSection.scss';

function DDResultSection(props) {
    return (
        <div className="Result-Section">
            <div className="Block-Heading">
                <img src="data/image/icon/icon6.svg" alt="Icon" />
                <p className="Title-3 Black-1">Impact</p>
            </div>
            <div className="Chips">
                {props.data.map((d, k) => (
                    <p key={`result-section-${k}`}>{d}</p>
                ))}
            </div>
        </div>
    );
}

export default DDResultSection;